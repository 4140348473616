"use client";

import { Sparkles } from "lucide-react";
import { client } from "@/lib/hono";
import toast from "@/lib/toast";
import { Progress } from "@/components/ui/progress";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { firestore } from "@/lib/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { usePayments } from "@/features/payments/hooks/usePayments";
import { T } from "@tolgee/react";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";

export default function GenerateInteractionsWithAI({
    videoId,
    setReloadVideoKey,
}: {
    videoId: string;
    setReloadVideoKey: (key: number) => void;
}) {
    const setPaymentModalOpen = usePayments(
        (state) => state.setPaymentModalOpen
    );

    const [progress, setProgress] = useState(0);
    const [generationId, setGenerationId] = useState<string | null>(null);
    const submittedRef = useRef(false);

    const queryClient = useQueryClient();

    const generateInteractions = async () => {
        const newGenerationId = uuidv4();
        setGenerationId(newGenerationId);

        console.log("generating interactions for video:", videoId);

        const resGenerate = await client.api.ai[
            "generate-video-interactions"
        ].$post({
            json: {
                videoId,
                generationId: newGenerationId,
            },
        });

        if (!resGenerate.ok) {
            if (resGenerate.status === 402) {
                setPaymentModalOpen(true, "PaymentWall");
            } else if (resGenerate.status === 409) {
                toast.error(
                    "יש לייצר כתוביות לסרטון לפני שמייצרים שאלות עם AI",
                    {
                        keyName:
                            "toast.error.must-generate-captions-before-generating-interactions",
                    }
                );
            } else {
                toast.error("קרתה שגיאה", {
                    keyName: "toast.error.default",
                });
            }

            setGenerationId(null);
            submittedRef.current = false;
            setProgress(0);
            return;
        }

        setProgress(100);

        toast.success("השאלות נוצרו בהצלחה!", {
            keyName: "toast.success.generate-video-interactions",
        });

        queryClient.invalidateQueries({
            queryKey: ["get-interactions", videoId],
        });

        setReloadVideoKey(Date.now());
    };

    useEffect(() => {
        if (generationId && !submittedRef.current) {
            submittedRef.current = true;

            const docId = `video-interactions-${generationId}`;
            console.log("Attempting to set up Firestore listener for:", docId);
            const unsubscribe = onSnapshot(
                doc(firestore, "ai-generations", docId),
                (doc) => {
                    console.log("Snapshot received for document:", docId);
                    if (doc.exists()) {
                        console.log("Document data:", doc.data());
                        const data = doc.data();
                        setProgress(data?.progress || 0);
                    } else {
                        console.log("Document does not exist yet.");
                    }
                },
                (error) => {
                    console.error(
                        "Error listening to Firestore document:",
                        error
                    );
                }
            );

            return () => unsubscribe();
        }
    }, [generationId, setPaymentModalOpen, videoId]);

    return (
        <div className="bg-card rounded-md border p-6 flex flex-col w-full justify-center items-center">
            <div className="mb-2 flex w-full justify-center items-center">
                <Sparkles className="fill-blue-600 text-blue-600 size-6" />
            </div>
            <h2 className="text-base font-semibold">
                <T
                    keyName="video-sheet.create-interactions-with-ai.title"
                    defaultValue="יצירת שאלות אינטראקטיביות עם AI"
                />
            </h2>
            <p className="mt-1 text-sm text-muted-foreground">
                <T
                    keyName="video-sheet.create-interactions-with-ai.description"
                    defaultValue="לחצו כאן ובתוך כמה שניות שאלות אינטראקטיביות על הסרטון יופיעו בנגן"
                />
            </p>
            {generationId ? (
                <div className="mt-4 flex w-full flex-row gap-4 items-center">
                    <p className="text-sm text-muted-foreground">{progress}%</p>
                    <Progress
                        indicatorClassName="bg-blue-600"
                        className="h-2"
                        value={progress}
                    />
                </div>
            ) : (
                <Button
                    variant="ai"
                    size="default"
                    onClick={generateInteractions}
                    className="mt-4 flex flex-row gap-2 items-center"
                >
                    <T
                        keyName="vidoe-sheet.generate-interactions-with-ai-btn"
                        defaultValue="יצירה"
                    />
                </Button>
            )}
        </div>
    );
}
