"use client";

import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { useInteractions } from "../hooks/interactionsProvider";
import { buttonVariants } from "@/components/ui/button";
import { EllipsisVertical, Pencil, Trash } from "lucide-react";
import TimestampInput from "@/components/ui/timestamp-input";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { T } from "@tolgee/react";
import { interactionTypeOptions } from "../lib/utils";

export default function InteractionsList() {
    const {
        interactions,
        getInteractionTitle,
        editInteraction,
        deleteInteraction,
    } = useInteractions();

    return (
        <div
            className={cn(
                "flex w-full flex-col gap-2",
                interactions.length > 0 && "mt-6"
            )}
        >
            {interactions.map((interaction, idx) => (
                <div
                    key={idx}
                    className="flex flex-row items-center gap-4 text-sm"
                >
                    <div className="flex flex-row w-full gap-2 items-center">
                        <span className="text-muted-foreground w-4">
                            {idx + 1}
                        </span>
                        <TimestampInput
                            valueInSeconds={interaction.timestamp}
                            onValueChange={() => {}}
                            triggerClassName="w-[130px]"
                            disabled
                        />
                        <Input
                            value={
                                interactionTypeOptions.find(
                                    (o) => o.id === interaction.type
                                )?.defaultValue
                            }
                            className="w-[100px]"
                            onChange={(e) => {}}
                            readOnly
                        />
                        <Input
                            value={getInteractionTitle(interaction.id)}
                            onChange={(e) => {}}
                            readOnly
                        />
                        <DropdownMenu dir="rtl">
                            <DropdownMenuTrigger>
                                <div
                                    className={cn(
                                        buttonVariants({
                                            variant: "secondary",
                                            size: "icon",
                                        }),
                                        "aspect-square"
                                    )}
                                >
                                    <EllipsisVertical className="size-4" />
                                </div>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <DropdownMenuItem
                                    onClick={() =>
                                        editInteraction(interaction.id)
                                    }
                                    className="flex flex-row gap-2 items-center"
                                >
                                    <Pencil className="size-4" />
                                    <T
                                        keyName="video-sheet.interactions-list.options.edit"
                                        defaultValue="עריכה"
                                    />
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                    onClick={() =>
                                        deleteInteraction(interaction.id)
                                    }
                                    className="flex flex-row gap-2 items-center"
                                >
                                    <Trash className="size-4" />
                                    <T
                                        keyName="video-sheet.interactions-list.options.delete"
                                        defaultValue="מחיקה"
                                    />
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </div>
            ))}
        </div>
    );
}
