"use client";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import TimestampInput from "@/components/ui/timestamp-input";
import { client } from "@/lib/hono";
import toast from "@/lib/toast";
import { cn } from "@/lib/utils";
import { VideoChapter } from "@dev-xsiter/shared-schema";
import { useQueryClient } from "@tanstack/react-query";
import { T } from "@tolgee/react";
import { LoaderCircle, Plus, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import GenerateChaptersWithAI from "./generateChaptersWithAI";

export default function ChaptersTab({
    videoId,
    initialChapters,
    lengthInSeconds,
    setReloadVideoKey,
}: {
    videoId: string;
    initialChapters: VideoChapter[];
    lengthInSeconds: number;
    setReloadVideoKey: (key: number) => void;
}) {
    const [chapters, setChapters] = useState<VideoChapter[]>(initialChapters);
    const [changes, setChanges] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setChapters(initialChapters);
    }, [initialChapters]);

    const queryClient = useQueryClient();

    const handleAddChapter = () => {
        setChanges(true);
        setChapters([...chapters, { title: "", start: 0, end: 0 }]);
    };

    const handleDeleteChapter = (index: number) => {
        setChanges(true);
        setChapters(chapters.filter((_, idx) => idx !== index));
    };

    const handleChange = (
        index: number,
        field: keyof VideoChapter,
        value: string | number
    ) => {
        setChanges(true);
        const updatedChapters = [...chapters];
        if (field === "start" || field === "end") {
            updatedChapters[index][field] = (value || 0) as number;
        } else {
            updatedChapters[index][field] = value as string;
        }
        setChapters(updatedChapters);
    };

    const handleSave = async () => {
        // Validation
        for (let i = 0; i < chapters.length; i++) {
            const chapter = chapters[i];

            // Check if start is before end
            if (chapter.start >= chapter.end) {
                toast.error("זמן ההתחלה חייב להיות לפני זמן הסיום", {
                    keyName: "toast.error.start-before-end",
                });
                return;
            }

            // Check if start and end are within the video length
            if (
                chapter.start > lengthInSeconds ||
                chapter.end > lengthInSeconds
            ) {
                toast.error("הזמן חורג מאורך הסרטון", {
                    keyName: "toast.error.exceeds-video-length",
                });
                return;
            }

            // Check if start of current chapter is after the end of the previous chapter
            if (i > 0 && chapter.start <= chapters[i - 1].end) {
                toast.error(
                    "זמן התחלה של פרק חייב להיות אחרי זמן סיום של הפרק הקודם",
                    {
                        keyName: "toast.error.overlapping-chapter",
                    }
                );
                return;
            }

            // Check if end of current chapter is before the start of the next chapter
            if (
                i < chapters.length - 1 &&
                chapter.end >= chapters[i + 1].start
            ) {
                toast.error(
                    "זמן סיום של פרק חייב להיות לפני זמן התחלה של הפרק הבא",
                    {
                        keyName: "toast.error.overlapping-next-chapter",
                    }
                );
                return;
            }
        }

        setLoading(true);

        const res = await client.api.storage.video[":videoId"].update.$post({
            param: { videoId },
            json: {
                chapters: JSON.stringify(chapters),
            },
        });

        const resJson = await res.json();
        console.log({ resJson });

        if (!res.ok) {
            toast.error("אירעה שגיאה בניסיון עדכון סרטון", {
                keyName: "toast.error.default",
            });
            setLoading(false);
            return;
        }

        queryClient.invalidateQueries({
            queryKey: ["get-video", videoId],
        });

        setReloadVideoKey(Date.now());
        toast.success("השינויים נשמרו בהצלחה!", {
            keyName: "toast.success.save-changes",
        });
        setLoading(false);
        setChanges(false);
    };

    return (
        <div className="mt-4">
            <div className="flex flex-row gap-2 justify-between items-center">
                <div>
                    <Label className="">
                        <T
                            keyName="video-sheet.chapters-tab.title"
                            defaultValue="פרקים"
                        />
                    </Label>
                    <p className="text-sm text-muted-foreground">
                        <T
                            keyName="video-sheet.chapters-tab.description"
                            defaultValue="מופיעים בתוך הנגן ומאפשרים לצופה לנווט בקלות בסרטון."
                        />
                    </p>
                </div>
                <Button
                    size="sm"
                    variant="outline"
                    className="flex flex-row items-center gap-1 w-fit"
                    onClick={handleAddChapter}
                >
                    <Plus className="size-4" />
                    <T
                        keyName="video-sheet.chapters-tab.save-btn"
                        defaultValue="פרק חדש"
                    />
                </Button>
            </div>

            <div className="flex flex-col gap-8">
                <div
                    className={cn(
                        "flex w-full flex-col gap-2",
                        chapters.length > 0 && "mt-6"
                    )}
                >
                    {chapters.map((chapter, idx) => (
                        <div
                            key={idx}
                            className="flex flex-row items-center gap-4 text-sm"
                        >
                            <div className="flex flex-row gap-2 items-center">
                                <span className="text-muted-foreground w-2">
                                    {idx + 1}
                                </span>

                                <TimestampInput
                                    valueInSeconds={chapter.start}
                                    onValueChange={(value) => {
                                        handleChange(idx, "start", value);
                                    }}
                                    triggerClassName="w-[85px]"
                                />

                                <span className="text-muted-foreground">
                                    <T
                                        keyName="video-sheet.chapters-tab.chapter.to"
                                        defaultValue="עד"
                                    />
                                </span>

                                <TimestampInput
                                    valueInSeconds={chapter.end}
                                    onValueChange={(value) => {
                                        handleChange(idx, "end", value);
                                    }}
                                    triggerClassName="w-[85px]"
                                />
                            </div>

                            <div className="flex flex-row w-full gap-2 items-center">
                                <Input
                                    value={chapter.title}
                                    onChange={(e) =>
                                        handleChange(
                                            idx,
                                            "title",
                                            e.target.value
                                        )
                                    }
                                    placeholder="שם הפרק"
                                />
                                <button
                                    onClick={() => handleDeleteChapter(idx)}
                                    className="text-muted-foreground hover:text-black p-1"
                                >
                                    <Trash className="size-4" />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                {changes && (
                    <Button
                        disabled={loading || !changes}
                        onClick={handleSave}
                        className="w-fit"
                    >
                        {loading ? (
                            <LoaderCircle className="size-4 animate-spin" />
                        ) : (
                            <T
                                keyName="video-sheet.chapters-tab.save-btn"
                                defaultValue="שמירה"
                            />
                        )}
                    </Button>
                )}

                {initialChapters.length === 0 && (
                    <GenerateChaptersWithAI
                        videoId={videoId}
                        setReloadVideoKey={setReloadVideoKey}
                    />
                )}
            </div>
        </div>
    );
}
