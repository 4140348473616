"use client";

import {
    createContext,
    useContext,
    useState,
    ReactNode,
    Context,
    Dispatch,
    SetStateAction,
    useEffect,
} from "react";
import InteractionModal from "../interactionModal/interactionModal";
import { GetVideoResponseType } from "@/features/storage/sheets/hooks/useGetVideo";
import { useGetInteractions } from "@/features/storage/sheets/hooks/useGetInteractions";
import { LoaderCircle } from "lucide-react";
import { useInteractionModalSteps } from "./useInteractionModalSteps";
import { Interaction, InteractionStep } from "../lib/types";
import { useSaveInteractions } from "./useSaveInteractions";
import { useInteractionEdit } from "./useInteractionEdit";
import { useInteractionsList } from "./useInteractionsList";

interface InteractionsProviderProps {
    children: ReactNode;
    video: GetVideoResponseType["data"];
    setReloadVideoKey: Dispatch<SetStateAction<number>>;
}

interface InteractionsContextType {
    // general
    interactions: Interaction[];
    selectedInteraction: Interaction | null;
    setInteractionModalOpen: Dispatch<SetStateAction<boolean>>;
    closeModal: () => void;
    initialInteractions: Interaction[] | undefined;

    // interactions list
    createInteraction: () => void;
    editInteraction: (id: string) => void;
    deleteInteraction: (id: string) => void;
    duplicateInteraction: (id: string) => void;
    getInteractionTitle: (id: string) => string;

    // save interactions
    saveChanges: () => Promise<void>;
    loading: boolean;
    changes: boolean;

    // interaction edit
    updateInteraction: (
        id: string,
        fieldsToUpdate: Partial<Interaction>
    ) => void;

    // interaction modal steps
    currentStep: InteractionStep;
    nextStep: () => void;
    prevStep: () => void;
    moveToStep: (step: InteractionStep) => void;
}

const InteractionsContext = createContext<InteractionsContextType | undefined>(
    undefined
);

export const useInteractions = () => {
    const context = useContext(
        InteractionsContext as Context<InteractionsContextType | undefined>
    );
    if (!context) {
        throw new Error(
            "useInteractions must be used within a InteractionsContext"
        );
    }
    return context;
};

export default function InteractionsProvider({
    children,
    video,
    setReloadVideoKey,
}: InteractionsProviderProps): JSX.Element {
    const [interactions, setInteractions] = useState<Interaction[]>([]);
    const [selectedInteraction, setSelectedInteraction] =
        useState<Interaction | null>(null);
    const [interactionModalOpen, setInteractionModalOpen] = useState(false);

    const { data, isPending } = useGetInteractions(video.id);

    useEffect(() => {
        if (!isPending && data?.data) setInteractions(data.data);
    }, [data?.data, isPending]);

    const { currentStep, setCurrentStep, nextStep, prevStep, moveToStep } =
        useInteractionModalSteps();

    const { loading, changes, setChanges, saveChanges } = useSaveInteractions({
        interactions,
        videoId: video.id,
        setReloadVideoKey,
    });

    const { updateInteraction } = useInteractionEdit({
        video,
        interactions,
        setInteractions,
        setSelectedInteraction,
        setChanges,
    });

    const {
        createInteraction,
        editInteraction,
        deleteInteraction,
        duplicateInteraction,
        getInteractionTitle,
    } = useInteractionsList({
        video,
        interactions,
        setInteractions,
        setSelectedInteraction,
        setChanges,
        setInteractionModalOpen,
    });

    const closeModal = () => {
        setInteractionModalOpen(false);
        setSelectedInteraction(null);
        setCurrentStep("time-and-type");
    };

    const value: InteractionsContextType = {
        interactions,
        selectedInteraction,
        setInteractionModalOpen,
        createInteraction,
        updateInteraction,
        deleteInteraction,
        duplicateInteraction,
        getInteractionTitle,
        saveChanges,
        loading,
        changes,
        editInteraction,
        currentStep,
        nextStep,
        prevStep,
        moveToStep,
        closeModal,
        initialInteractions: data?.data,
    };

    return (
        <InteractionsContext.Provider value={value as any}>
            <InteractionModal
                open={interactionModalOpen}
                setOpen={setInteractionModalOpen}
            />
            {isPending ? <Loading /> : children}
        </InteractionsContext.Provider>
    );
}

const Loading = () => (
    <div className="py-24 flex w-full justify-center items-center">
        <LoaderCircle className="size-4 animate-spin" />
    </div>
);
